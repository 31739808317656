.creativ {
margin-top: 6.75rem;
    &__row {
    }

    &__item {
    }

    &__block {
        height: 24.063rem;
        &:hover {
            .creativ__link {
                opacity: 1;
            }
        }
        @media screen and (max-width:768px) {
            margin-top: 2rem;
        }
    }
    
    &__link {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 125%;
        text-decoration-line: underline;
        color: #FFFFFF;
        transition: 0.3s;
        opacity: 0;
        &:hover {
            color: #FFFFFF;
            opacity: 0.7;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        height: 100%;
            
        background-size:cover;
        background-repeat:no-repeat;
        box-shadow: 0px 23px 30px rgba(179, 137, 93, 0.5);
        span {
            font-family: 'Georgia', sans-serif;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.2rem;
            font-size:2.25rem;
            line-height: 125%;
            color: #FFFFFF;
            margin-bottom: 1.875rem;
            @media screen and (max-width:1024px) {
                font-size: 1.8rem;
            }
            @media screen and (max-width:768px) {
                font-size:2.25rem;
            }
        }
    }
}
.container {
}
.row {
}
.col-md-4 {
}
