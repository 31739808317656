.poem {
margin-bottom: 5rem;

    &__row {
        position: relative;
    }

    &__item {
    }
    &__title {
        font-family: 'Georgia', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 2.25rem;
        line-height: 125%;
        color: #3F3939;
        margin-top: 2.375rem;

        position: relative;
        &::after {
            content:"";
            position: absolute;
            opacity: 0.7;
            width: 80%;
            border-bottom: 1px solid #3D3B39;
            left:0;
            // top: 5rem;
            bottom: -1rem;
        }
        span {
          font-size: 1.5rem;
        }
    }
}
.container {
}
.gen-st {

    &__title {
    }
}
.gen-of-text {

    &__item {
    }
}
.stihs {
}
#button-up {
    display:none;
    // left: 0;
    right: 1rem;
    margin: 0;
    position: fixed;
    bottom: 6.25rem;
    outline:none;
    width: 3.25rem;
    color: white;;
    z-index: 1000;
    // line-height: 30px;
    text-decoration: none;
    padding: 1rem;
    background: #3F3939;
    text-align: center;
    // border-radius: 0 4px 4px 0;
    box-shadow: 0 5px 20px white;

    cursor:pointer;
}
.tabheader__item_active {
    .gen-kts__arrow {
        img {
            transform: rotate(90deg);
        }
    }
}
