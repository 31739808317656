.gen-creat {

    &__row {
        position: relative;
        padding-bottom: 1.563rem;
    }

    &__item {
    }

    &__bg {
        position: absolute;
        width: 40%;
        height: 100%;
        left: 0;
        background: #3F3939;
        z-index: 1;
        @media screen and (max-width:1024px) {
            width: 60%;
        }
        @media screen and (max-width:550px) {
            width: 90%;
        }
    }

    &__bg2 {
        position: absolute;
        width: 70%;
        height: 100%;
        left: 0;
        background: #3F3939;
        z-index: 1;
        @media screen and (max-width:1254px) {
            width: 80%;
        }
        @media screen and (max-width:1102px) {
            width: 85%;
        }
        @media screen and (max-width:1024px) {
            width: 90%;
        }
        @media screen and (max-width:550px) {
            width: 100%;
        }
    }

    &__top {
        position: relative;
        z-index: 3;
    }

    &__nav {
        img {
            width: 1.125rem;
            height: 1.125rem;
        }
        span {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 125%;
            color: rgba(255, 255, 255, 0.29);
        }
        a {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 125%;
            color: rgba(255, 255, 255, 0.29);
        }
    }

    &__title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 3.75rem;
        line-height: 125%;
        color: #D6AB7D;
        margin-top: 2.375rem;
        @media screen and (max-width:550px) {
          font-size: 2.6rem;

        }
    }
}

.gen-st {
margin-top: 2.813rem;
    &__row {
        position: relative;
    }

    &__item {
    }

    &__top {
    }

    &__nav {
        img {
            width: 1.125rem;
            height: 1.125rem;
        }
        span {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 125%;
            color: #3F3E3E;
        }
        a {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 125%;
            color: #3F3E3E;
        }
    }

    &__title {
        font-family: 'Georgia', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 2.25rem;
        line-height: 125%;
        color: #3F3939;
        margin-top: 2.375rem;

        &::after {
            content:"";
            position: absolute;
            width: 70%;
            height: 1px;
            background:#3D3B39;
            left:0;
            bottom: -2rem;
        }
        span {
          font-size: 1.5rem;
        }
    }
}

.gen-scroll-right {
    height: 62.5rem;
    overflow-y: auto;
    padding-right: 1.5rem;
        &::-webkit-scrollbar { width: 0.5rem; height: 100%;}
        &::-webkit-scrollbar-track { background:url('../img/Line.svg'); background-size:0.188rem; background-repeat:no-repeat; background-position: right;}
        &::-webkit-scrollbar-thumb {
            width: 15px;
            background-color: #3F3939;
            border-radius: 3px;
        }
}
