.poez {
margin-top: 10.75rem;
    &__row {
    }

    &__item {
    }

    &__block {
        @media screen and (max-width:992px) {
            margin-top: 8rem;
        }
    }

    &__content {
        box-shadow: 0px 17px 30px rgba(214, 171, 125, 0.3);
        min-height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 1.438rem;
    }

    &__img {
        margin-top: -4rem;
        img {
            // width: 60%;
            width: 12.813rem;
                height: 18.125rem;
            @media screen and (max-width:992px) {
                // width: 50%;

            }
        }
    }

    &__title {
        font-family: 'Georgia', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 125%;
        color: #32201F;
        margin-top: 1.563rem;
        flex: 1 1 auto;
        @media screen and (max-width:1024px) {
            font-size: 1rem;
        }
    }

    &__next {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 125%;
        text-decoration-line: underline;
        color: #32201F;
        margin-top: 2.438rem;
        display: block;
        @media screen and (max-width:1024px) {
            font-size: 1rem;
        }
    }
}
.container {
}
.row {
}
.col-lg-3 {
}
