.sec-1 {
    &__row {
        background: url('../img/s1-1.png'), url('../img/s1-2.png');
        background-size: 50% 130%, 50% 83%;
        background-repeat: no-repeat, no-repeat;
        background-position: 0px 0px, right 0;
        @media screen and (max-width:1196px) {
          background-size: 50% 100%, 50% 83%;
      }
        @media screen and (max-width:1024px) {
            background-size: 50% 100%, 50% 83%;
        }
        @media screen and (max-width:576px) {
            background: url('../img/s1-1.png');
            background-size: 100% 100%;
            background-repeat: no-repeat, no-repeat;
            background-position: top;
        }
        @media screen and (max-width:550px) {
          background: url('../img/s1-1.png');
          background-size: 150% 100%;
          background-repeat: no-repeat, no-repeat;
          background-position: top;

          box-shadow: 0px 30rem 148px -12px rgba(63, 57, 57, 1) inset;
      }

    }

    &__item {
        padding-top: 10rem;
    }

    &__blocks {
        display: flex;
        align-items: center;
    }

    &__bottom {
        display: flex;
        align-items: center;
    }
}
.container {
}
.row {
}
.col-md-6 {
}
.sec-1-block {
color:white;
@media screen and (max-width:576px) {
  &:nth-child(1) {
    order: 2;
  }
  &:nth-child(2) {
    order: 1;
    margin-bottom: 2rem;
  }
}
    &__item {
    }

    &__images {
        display: flex;
        justify-content: flex-end;
        margin-right: -0.8rem;
        align-items: flex-end;
        @media screen and (max-width:576px) {
            justify-content: flex-start;
            align-items:flex-start;
        }
    }

    &__label {
        text-align: right;
        @media screen and (max-width:576px) {
            text-align: left;
            margin-top: 2rem;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 3.75rem;
        line-height: 125%;
        @media screen and (max-width:1024px) {
            font-size: 2rem;
        }
    }

    &__subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 160%;
        margin-top: 3.375rem;
        @media screen and (max-width:576px) {
          display: none;
        }
    }
}
.s2131 {
  display: none;
  @media screen and (max-width:576px) {
    display: block;
    width: 70%;
  }
}

.sec-1-img2 {
    width: 7.063rem;
    height: 9.063rem;
    margin-right: 2.813rem;
    margin-bottom: 4rem;
    @media screen and (max-width:1024px) {
        display: none;
    }
    @media screen and (max-width:576px) {
      display: block;
      order: 2;
      margin-left: 2.813rem;
    }
    @media screen and (max-width:550px) {
      width: 20%;
      height: auto;
    }
}
.sec-1-img1 {
    width:21.688rem;
    height: 28.875rem;
    @media screen and (max-width:576px) {
      display: block;
      order: 1;
   }
   @media screen and (max-width:550px) {
    width: 50%;
    height: auto;
  }
}
.sec-1-bt {

    &__item {
    }

    &__bot {
        img {
            cursor: pointer;
            height: 3.563rem;
            width:0.938rem;
        }
    }

    &__active {
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 170%;
        color: #FFFFFF;
        opacity: 0.5;
        @media screen and (max-width:576px) {
          font-size: 1.3rem;
       }
    }

    &__none {
        font-style: normal;
        font-weight: bold;
        font-size: 1.563rem;
        line-height: 170%;
        color: #FFFFFF;
        opacity: 0.3;
        @media screen and (max-width:576px) {
          font-size: 1.3rem;
       }
    }

    &__info {
        display: flex;
        justify-content: space-around;
        margin-top: 2.875rem;
        font-family: 'Raleway', sans-serif;
        @media screen and (max-width:576px) {
          flex-direction: column;
          transform: translateY(-5rem);
       }
    }

    &__in1 {
        p {
            &:first-child {
                font-style: normal;
                font-weight: normal;
                font-size: 1.125rem;
                line-height: 125%;
                color: #F1802E;
            }
            &:last-child {
                font-family: 'Georgia', sans-serif;
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 125%;
                color: #FFFFFF;
                opacity: 0.7;
                margin-left: 4rem;
                @media screen and (max-width:1024px) {
                   margin-left: 0;
                   font-size: 1rem;
                }
            }
        }
    }
}
.hid {
  display: none;
}
.modal-block-1 {
position: fixed;
top: 0;
padding-top: 7rem;
left: 0;
width: 100%;
height: 100%;
background-color: #000000a4;
z-index: 2000;
  &__row {
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 0 1rem;
  }

  &__item {
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  &__img {
  }
}
.bi {
}
.bi-x-lg {
}
