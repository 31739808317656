.kids {
color:white;
margin-top: 7.875rem;
background-image: url('../img/s2-bg.png');
background-size:cover;
background-repeat:no-repeat;
margin-bottom: 9.625rem;
// background-position:center;
    &__row {
        position: relative;
    }

    &__item {
    }
}
.container {
}
.gen-title {

    &__active {
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 170%;
        color: #FFFFFF;
        // opacity: 0.5;
    }

    &__none {
        font-style: normal;
        font-weight: 400;
        font-size: 1.563rem;
        line-height: 170%;
        color: #FFFFFF;
        // opacity: 0.3;
    }

    &__text {
        font-family: 'Georgia', sans-serif;
        color:white;
        font-style: normal;
        font-weight: 600;
        font-size: 3.75rem;
        line-height: 125%;
        letter-spacing: 3px;
        &::first-letter {
            color: #F1802E;
        }
        &::after {
            content: "";
            width:41%;
            height: 2px;
            position: absolute;
            background:white;
            top: 9.375rem;
            left: 0;
            opacity: 0.7;
            @media screen and (max-width:1040px) {
                // top: 9.375rem;
                width:59%;
            }
            @media screen and (max-width:868px) {
                width:65%;
            }
            @media screen and (max-width:550px) {
                width:100%;
            }
        }
        @media screen and (max-width:550px) {
            font-size: 2.75rem;
        }
    }
}

.kids-top {
margin-top: 4.75rem;
    &__block {
    }

    &__text {
        span {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 160%;
        }
        @media screen and (max-width:768px) {
            margin-bottom: 2rem;
        }
    }

    &__img {
        // transform: translateY();
        display: flex;
        justify-content: flex-end;
        margin-top: -11.25rem;
        @media screen and (max-width:1024px) {
            margin-top: 0;
        }
        img {
          &:first-child {
            @media screen and (max-width:768px) {
              width:60%;
          }
          }

        }
        @media screen and (max-width:768px) {
           justify-content: flex-start;
        }
    }
}
.col-md-4 {
}
.kids-center {
margin-top: 1rem;
@media screen and (max-width:1024px) {
    margin-top: 2rem;
}
    &__block {
      @media screen and (max-width:576px) {
        &:nth-child(1) {
          order: 3;
        }
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(1) {
          order: 2;
        }
      }

    }

    &__img {
        display: flex;
        align-items: flex-start;
        img {
          &:first-child {
            @media screen and (max-width:576px) {
             margin-top: 2rem;
            }
          }
            &:last-child {
                margin-left: 1.188rem;
                @media screen and (max-width:550px) {
                   margin-left: 0;
                   width:30%;
                }
                @media screen and (max-width:576px) {
                  display: none;
               }
            }
            @media screen and (max-width:550px) {
                width:60%;
            }
        }
        @media screen and (max-width:550px) {
            flex-direction:column;
            align-items:center;
        }
    }

    &__text {
        margin-top: 11rem;
        span {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 1rem;
            line-height: 160%;
        }
        @media screen and (max-width:768px) {
            margin-top: 2rem;
        }
    }
}
.s23png {
  display: none;
  @media screen and (max-width:576px) {
    display: block;
    width: 30%;
    height: 10rem;
    margin-left: 2rem;
    margin-top: 23%;
  }
  // @media screen and (max-width:550px) {
  //   width: 5rem;
  // }
}
.kids-bottom {
margin-top: 6.938rem;
font-family: 'Raleway', sans-serif;
    &__block {
      &:nth-child(2) {
        @media screen and (max-width:576px) {
          display: none;
       }
      }
    }

    &__item {
        width:43.375rem;
        background-image:url('../img/s2-4.png');
        background-size:cover;
        color: #3F3939;
        padding-top: 2.688rem;
        padding-bottom: 1.563rem;
        padding-left: 2.063rem;
        padding-right: 2.063rem;
        @media screen and (max-width:550px) {
           width:27.4rem;
        }
    }

    &__text1 {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 160%;
        margin-bottom: 1rem;
    }

    &__descr {
        display: flex;
        align-items: flex-start;
        img {
            width:3rem;
            height: 2rem;
        }
        span {
            margin-top: 1rem;
            margin-left: 0.625rem;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 160%;
        }
    }

    &__auth {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 140%;
        @media screen and (max-width:550px) {
            margin-top: 1rem;
        }
    }
    &__img223 {
      display: none;

      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      font-style: normal;
      margin-top: 3rem;
      @media screen and (max-width:550px) {
        display: flex;
      }
      p {
          font-weight: normal;
          font-size: 0.975rem;
          line-height: 160%;
          margin-top: 0.4rem;
          text-align: left;
      }
    }
    &__img2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        font-style: normal;
        p {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 160%;
            margin-top: 0.4rem;
        }
        @media screen and (max-width:1040px) {
            display: none;
        }
        @media screen and (max-width:768px) {
            display: block;
            margin-top: 1rem;
            align-items: flex-start;
            text-align: left;
        }
        img {
            @media screen and (max-width:768px) {
                width:60%;
            }
        }
    }
}
