.footer {
color:white;
padding-bottom: 2rem;
    &__row {
        position: relative;
    }

    &__item {

    }

    &__logo {
        position: absolute;
        left: 0;
        span {
            position: absolute;
            bottom:1rem;
            right: -7rem;
            font-family: 'Menorah', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 170%;
            @media screen and (max-width:1024px) {
                left: 7rem;
                right: auto;
            }
        }
        @media screen and (max-width:1024px) {
            position: relative;
        }
    }

    &__img {
        img {
            @media screen and (max-width:422px) {
                width: 50%;
            }
        }
    }

    &__bot {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 170%;
        opacity: 0.5;
        margin-top: 1rem;
        @media screen and (max-width:1024px) {
            justify-content: flex-end;
        }
    }
}

.footer-nav {
display: flex;
flex-wrap: wrap;
justify-content: flex-end;
padding-top: 4.813rem;

    &__block {
        margin-left: 8.25rem;
        &:first-child {
            margin-left: 0;
        }
        @media screen and (max-width:1224px) {
            margin-left: 5.25rem;
        }
        @media screen and (max-width:1024px) {
            flex: 0 0 auto;
            width: 50%;
            margin-left: 0;
            margin-top: 2rem;
        }
    }

    &__title {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 170%;
        margin-bottom: 1.875rem;
        display: block;
        color:white;
        &:hover {
          color:white;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        a {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 170%;
            color: #FFFFFF;
            opacity: 0.6;
            margin-bottom: 1.125rem;
        }
    }
}
.footer-nav2 {
  margin-left: 8.25rem;
  @media screen and (max-width:1024px) {
    margin: 2rem 0;
    width: 100%;
  }
      &__block {
        margin-bottom: 1.875rem;
      }

      &__title {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 170%;
          margin-bottom: 1.875rem;
          color:white;
          &:hover {
            color:white;
          }
      }

      &__links {
          display: flex;
          flex-direction: column;
          a {
              font-style: normal;
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 170%;
              color: #FFFFFF;
              opacity: 0.6;
              margin-bottom: 1.125rem;
          }
      }
  }
