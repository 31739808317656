.gen-kts {
margin-top: 6.563rem;
padding-bottom: 7rem;
    &__row {
    }

    &__item {
    }

    &__block {
        @media screen and (max-width:578px) {
            margin-bottom: 6rem;
        }
    }

    &__images {
        height: 22.875rem;
        width: 18.5rem;
        border: 1px solid #3D3B39;
        @media screen and (max-width:1024px) {
            height: 14.875rem;
            width: 11.5rem;
        }
        @media screen and (max-width:578px) {
            height: 22.875rem;
             width: 18.5rem;
        }
    }

    &__img {
        background: #EBD3B4;
        box-shadow: 0px 17px 30px rgba(210, 142, 95, 0.3);
        height: 100%;
        width: 100%;
        transform: translate(1.875rem, 1.875rem);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__img2 {
        background: #F1802E;
    }

    &__items {
        display: flex;
        flex-direction: column;
    }

    &__itemg {
        border-bottom: 1px solid #3F3939;
        padding-bottom: 1rem;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 3rem;
        @media screen and (max-width:578px) {
            padding-left: 1rem;
        }
        span {
            font-family: 'Georgia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 125%;
            text-transform: uppercase;
            color: #32201F;
        }
    }
    &__itemg123 {
      border-bottom: 1px solid #3F3939;
      padding-bottom: 1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 3rem;
      @media screen and (max-width:578px) {
          padding-left: 1rem;
      }
      span {
          font-family: 'Georgia', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1.125rem;
          line-height: 125%;
          text-transform: uppercase;
          color: #32201F;
      }
  }
    &__start {
        display: flex;
        align-items: center;
        img {
            margin-right: 0.375rem;
        }
    }
    &__arrow {
        margin-right: 1rem;
        img {
            width: 2.375rem;
        height: auto;
        }
    }
}
