:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: 1300px;
  --light-color: #fff;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Menorah";
  src: url("../fonts/../fonts/MenorahGrotesk-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Georgia";
  src: url("../fonts/../fonts/georgia.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html, body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  position: fixed !important;
  z-index: 1002;
  width: 100%;
  left: 0;
  top: 0;
}
.header-desk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.563rem 0;
}
.header-desk__logo span {
  font-family: "Menorah", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 170%;
  color: #FFFFFF;
}
.header-desk__nav {
  display: none;
}
.header-desk__nav a {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 170%;
  color: #FFFFFF;
  margin-left: 1.563rem;
  transition: 0.3s;
}
.header-desk__nav a:hover {
  opacity: 0.6;
}
.header-desk__nav a:first-child {
  margin-left: 0;
}

.nav {
  display: block;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
#menu {
  background: #120b07f8;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed !important;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1100;
}
#menu.open {
  opacity: 1;
  visibility: visible;
}
#menu.open li:nth-child(1) {
  animation-delay: 0.1s;
}
#menu.open li:nth-child(2) {
  animation-delay: 0.2s;
}
#menu.open li:nth-child(3) {
  animation-delay: 0.3s;
}
#menu.open li:nth-child(4) {
  animation-delay: 0.4s;
}
#menu.open li:nth-child(5) {
  animation-delay: 0.5s;
}
#menu.open li:nth-child(6) {
  animation-delay: 0.6s;
}
#menu.open li:nth-child(7) {
  animation-delay: 0.7s;
}
#menu.open li:nth-child(8) {
  animation-delay: 0.8s;
}
#menu.open li:nth-child(9) {
  animation-delay: 0.9s;
}
#menu.open li:nth-child(10) {
  animation-delay: 1s;
}
#menu.open li {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
#menu ul:hover a {
  opacity: 0.5;
}
#menu ul a {
  -webkit-transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
}
#menu ul a:hover {
  opacity: 1;
}
#menu .main-nav {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#menu .main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .main-nav a {
  color: #ffffff;
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
}
#menu .main-nav a:first {
  padding-top: 0;
}
#menu .menu-footer {
  padding: 2rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
#menu .menu-footer ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .menu-footer ul li {
  display: inline;
  margin: 0 1rem;
}
#menu .menu-footer a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
  z-index: 1110;
}
.toggle-menu span {
  background-color: #fff;
  content: "";
  display: block;
  height: 2px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before, .toggle-menu span:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before {
  top: 7px;
}
.toggle-menu span:after {
  top: -7px;
}
.toggle-menu.active span {
  background-color: transparent;
  transition: background 0.2s ease-out;
}
.toggle-menu.active span:before, .toggle-menu.active span:after {
  transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
}
.toggle-menu.active span:before {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
}
.toggle-menu.active span:after {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
}

.head-bg {
  background: #3F3939;
  transition: 0.3s;
}

.sec-1__row {
  background: url("../img/s1-1.png"), url("../img/s1-2.png");
  background-size: 50% 130%, 50% 83%;
  background-repeat: no-repeat, no-repeat;
  background-position: 0px 0px, right 0;
}
@media screen and (max-width: 1196px) {
  .sec-1__row {
    background-size: 50% 100%, 50% 83%;
  }
}
@media screen and (max-width: 1024px) {
  .sec-1__row {
    background-size: 50% 100%, 50% 83%;
  }
}
@media screen and (max-width: 576px) {
  .sec-1__row {
    background: url("../img/s1-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: top;
  }
}
@media screen and (max-width: 550px) {
  .sec-1__row {
    background: url("../img/s1-1.png");
    background-size: 150% 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: top;
    box-shadow: 0px 30rem 148px -12px #3f3939 inset;
  }
}
.sec-1__item {
  padding-top: 10rem;
}
.sec-1__blocks {
  display: flex;
  align-items: center;
}
.sec-1__bottom {
  display: flex;
  align-items: center;
}

.sec-1-block {
  color: white;
}
@media screen and (max-width: 576px) {
  .sec-1-block:nth-child(1) {
    order: 2;
  }
  .sec-1-block:nth-child(2) {
    order: 1;
    margin-bottom: 2rem;
  }
}
.sec-1-block__images {
  display: flex;
  justify-content: flex-end;
  margin-right: -0.8rem;
  align-items: flex-end;
}
@media screen and (max-width: 576px) {
  .sec-1-block__images {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.sec-1-block__label {
  text-align: right;
}
@media screen and (max-width: 576px) {
  .sec-1-block__label {
    text-align: left;
    margin-top: 2rem;
  }
}
.sec-1-block__title {
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 125%;
}
@media screen and (max-width: 1024px) {
  .sec-1-block__title {
    font-size: 2rem;
  }
}
.sec-1-block__subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 160%;
  margin-top: 3.375rem;
}
@media screen and (max-width: 576px) {
  .sec-1-block__subtitle {
    display: none;
  }
}

.s2131 {
  display: none;
}
@media screen and (max-width: 576px) {
  .s2131 {
    display: block;
    width: 70%;
  }
}

.sec-1-img2 {
  width: 7.063rem;
  height: 9.063rem;
  margin-right: 2.813rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 1024px) {
  .sec-1-img2 {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .sec-1-img2 {
    display: block;
    order: 2;
    margin-left: 2.813rem;
  }
}
@media screen and (max-width: 550px) {
  .sec-1-img2 {
    width: 20%;
    height: auto;
  }
}

.sec-1-img1 {
  width: 21.688rem;
  height: 28.875rem;
}
@media screen and (max-width: 576px) {
  .sec-1-img1 {
    display: block;
    order: 1;
  }
}
@media screen and (max-width: 550px) {
  .sec-1-img1 {
    width: 50%;
    height: auto;
  }
}

.sec-1-bt__bot img {
  cursor: pointer;
  height: 3.563rem;
  width: 0.938rem;
}
.sec-1-bt__active {
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 170%;
  color: #FFFFFF;
  opacity: 0.5;
}
@media screen and (max-width: 576px) {
  .sec-1-bt__active {
    font-size: 1.3rem;
  }
}
.sec-1-bt__none {
  font-style: normal;
  font-weight: bold;
  font-size: 1.563rem;
  line-height: 170%;
  color: #FFFFFF;
  opacity: 0.3;
}
@media screen and (max-width: 576px) {
  .sec-1-bt__none {
    font-size: 1.3rem;
  }
}
.sec-1-bt__info {
  display: flex;
  justify-content: space-around;
  margin-top: 2.875rem;
  font-family: "Raleway", sans-serif;
}
@media screen and (max-width: 576px) {
  .sec-1-bt__info {
    flex-direction: column;
    transform: translateY(-5rem);
  }
}
.sec-1-bt__in1 p:first-child {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  color: #F1802E;
}
.sec-1-bt__in1 p:last-child {
  font-family: "Georgia", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 125%;
  color: #FFFFFF;
  opacity: 0.7;
  margin-left: 4rem;
}
@media screen and (max-width: 1024px) {
  .sec-1-bt__in1 p:last-child {
    margin-left: 0;
    font-size: 1rem;
  }
}

.hid {
  display: none;
}

.modal-block-1 {
  position: fixed;
  top: 0;
  padding-top: 7rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a4;
  z-index: 2000;
}
.modal-block-1__row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.modal-block-1__close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
.kids {
  color: white;
  margin-top: 7.875rem;
  background-image: url("../img/s2-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 9.625rem;
}
.kids__row {
  position: relative;
}
.gen-title__active {
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 170%;
  color: #FFFFFF;
}
.gen-title__none {
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 170%;
  color: #FFFFFF;
}
.gen-title__text {
  font-family: "Georgia", sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 125%;
  letter-spacing: 3px;
}
.gen-title__text::first-letter {
  color: #F1802E;
}
.gen-title__text::after {
  content: "";
  width: 41%;
  height: 2px;
  position: absolute;
  background: white;
  top: 9.375rem;
  left: 0;
  opacity: 0.7;
}
@media screen and (max-width: 1040px) {
  .gen-title__text::after {
    width: 59%;
  }
}
@media screen and (max-width: 868px) {
  .gen-title__text::after {
    width: 65%;
  }
}
@media screen and (max-width: 550px) {
  .gen-title__text::after {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .gen-title__text {
    font-size: 2.75rem;
  }
}

.kids-top {
  margin-top: 4.75rem;
}
.kids-top__text span {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 160%;
}
@media screen and (max-width: 768px) {
  .kids-top__text {
    margin-bottom: 2rem;
  }
}
.kids-top__img {
  display: flex;
  justify-content: flex-end;
  margin-top: -11.25rem;
}
@media screen and (max-width: 1024px) {
  .kids-top__img {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .kids-top__img img:first-child {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .kids-top__img {
    justify-content: flex-start;
  }
}

.kids-center {
  margin-top: 1rem;
}
@media screen and (max-width: 1024px) {
  .kids-center {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .kids-center__block:nth-child(1) {
    order: 3;
  }
  .kids-center__block:nth-child(1) {
    order: 1;
  }
  .kids-center__block:nth-child(1) {
    order: 2;
  }
}
.kids-center__img {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 576px) {
  .kids-center__img img:first-child {
    margin-top: 2rem;
  }
}
.kids-center__img img:last-child {
  margin-left: 1.188rem;
}
@media screen and (max-width: 550px) {
  .kids-center__img img:last-child {
    margin-left: 0;
    width: 30%;
  }
}
@media screen and (max-width: 576px) {
  .kids-center__img img:last-child {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .kids-center__img img {
    width: 60%;
  }
}
@media screen and (max-width: 550px) {
  .kids-center__img {
    flex-direction: column;
    align-items: center;
  }
}
.kids-center__text {
  margin-top: 11rem;
}
.kids-center__text span {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 160%;
}
@media screen and (max-width: 768px) {
  .kids-center__text {
    margin-top: 2rem;
  }
}

.s23png {
  display: none;
}
@media screen and (max-width: 576px) {
  .s23png {
    display: block;
    width: 30%;
    height: 10rem;
    margin-left: 2rem;
    margin-top: 23%;
  }
}

.kids-bottom {
  margin-top: 6.938rem;
  font-family: "Raleway", sans-serif;
}
@media screen and (max-width: 576px) {
  .kids-bottom__block:nth-child(2) {
    display: none;
  }
}
.kids-bottom__item {
  width: 43.375rem;
  background-image: url("../img/s2-4.png");
  background-size: cover;
  color: #3F3939;
  padding-top: 2.688rem;
  padding-bottom: 1.563rem;
  padding-left: 2.063rem;
  padding-right: 2.063rem;
}
@media screen and (max-width: 550px) {
  .kids-bottom__item {
    width: 27.4rem;
  }
}
.kids-bottom__text1 {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  margin-bottom: 1rem;
}
.kids-bottom__descr {
  display: flex;
  align-items: flex-start;
}
.kids-bottom__descr img {
  width: 3rem;
  height: 2rem;
}
.kids-bottom__descr span {
  margin-top: 1rem;
  margin-left: 0.625rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 160%;
}
.kids-bottom__auth {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
}
@media screen and (max-width: 550px) {
  .kids-bottom__auth {
    margin-top: 1rem;
  }
}
.kids-bottom__img223 {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  font-style: normal;
  margin-top: 3rem;
}
@media screen and (max-width: 550px) {
  .kids-bottom__img223 {
    display: flex;
  }
}
.kids-bottom__img223 p {
  font-weight: normal;
  font-size: 0.975rem;
  line-height: 160%;
  margin-top: 0.4rem;
  text-align: left;
}
.kids-bottom__img2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  font-style: normal;
}
.kids-bottom__img2 p {
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 160%;
  margin-top: 0.4rem;
}
@media screen and (max-width: 1040px) {
  .kids-bottom__img2 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .kids-bottom__img2 {
    display: block;
    margin-top: 1rem;
    align-items: flex-start;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .kids-bottom__img2 img {
    width: 60%;
  }
}

.study {
  background: #DFD0C0;
  font-family: "Raleway", sans-serif;
}
.study__row {
  position: relative;
  background: url("../img/s3-5.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: bottom;
  background-size: 100% 40rem;
}
@media screen and (max-width: 550px) {
  .study__row {
    background-size: 240% 40rem;
  }
}
.study-top {
  display: flex;
}
.study-top__block:last-child {
  background: url("../img/s3-bg1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right -2rem;
  color: #FFFFFF;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .study-top__block:last-child {
    margin-top: 2rem;
  }
}
.study-top__text {
  max-width: 32.25rem;
}
.study-top__top {
  padding: 0 1rem;
  margin-bottom: 1.313rem;
}
.study-top__top img {
  margin-left: 0.4rem;
}
.study-top__top p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  opacity: 0.7;
  margin-left: 3rem;
  max-width: 24.688rem;
}
@media screen and (max-width: 768px) {
  .study-top__top p {
    max-width: none;
  }
}
.study-top__top span {
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 125%;
  opacity: 0.7;
}
@media screen and (max-width: 1024px) {
  .study-top__top span {
    font-size: 0.7rem;
    margin-right: 0;
  }
}

.fol-d {
  margin-left: 4rem;
}

.study-top-title {
  font-family: "Open Sans", sans-serif;
  padding-top: 3rem;
}
.study-top-title__active {
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 170%;
  color: #3F3939;
}
.study-top-title__none {
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 170%;
  color: #3F3939;
}
.study-top-title__txt {
  font-family: "Georgia", sans-serif;
  color: #3F3939;
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 125%;
  letter-spacing: 3px;
  margin-bottom: 5.5rem;
}
.study-top-title__txt::first-letter {
  color: #F1802E;
}
.study-top-title__txt::after {
  content: "";
  width: 41%;
  height: 2px;
  position: absolute;
  background: black;
  top: 13.375rem;
  left: 0;
  opacity: 0.7;
}
@media screen and (max-width: 1040px) {
  .study-top-title__txt::after {
    width: 59%;
  }
}
@media screen and (max-width: 868px) {
  .study-top-title__txt::after {
    width: 65%;
  }
}
@media screen and (max-width: 550px) {
  .study-top-title__txt::after {
    width: 100%;
  }
}

.fold-3 {
  width: 11.188rem;
  height: 10.188rem;
}
@media screen and (max-width: 1024px) {
  .fold-3 {
    margin: 2rem 0;
  }
}
@media screen and (max-width: 575px) {
  .fold-3 {
    order: 3;
    display: inline-block;
    position: absolute;
    bottom: -1.9rem;
    right: 0;
    height: 23%;
  }
}

.fold-2 {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .fold-2 {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .fold-2 {
    order: 2;
    display: inline-block;
  }
}

.study-center {
  margin-top: 5.438rem;
}
.study-center__item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1024px) {
  .study-center__item {
    flex-direction: column;
    align-items: flex-start;
  }
}
.study-center__text {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  color: #000000;
  max-width: 33rem;
  margin: 0 2rem;
  margin-top: 3rem;
}
@media screen and (max-width: 1024px) {
  .study-center__text {
    margin: 2rem 0 0 0;
    max-width: none;
  }
}
@media screen and (max-width: 575px) {
  .study-center__text {
    order: 1;
  }
}

.study-bottom {
  margin-top: 6.375rem;
  padding-bottom: 5.688rem;
}
.study-bottom__item {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 814px) {
  .study-bottom__item {
    flex-direction: column;
    align-items: flex-start;
  }
}
.study-bottom__left {
  margin-right: 1.938rem;
}
.study-bottom__left p {
  max-width: 32.188rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  color: #000000;
}
@media screen and (max-width: 814px) {
  .study-bottom__left p {
    max-width: none;
  }
}
.study-bottom__title {
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 160%;
  color: #F1802E;
}
.job {
  background-color: #ebe9e6f8;
}
.job__row {
  background: url("../img/s4-1bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 6.75rem;
  padding-bottom: 11.438rem;
}
@media screen and (max-width: 768px) {
  .job-top__block:nth-child(1) {
    order: 2;
  }
}
@media screen and (max-width: 768px) {
  .job-top__block:nth-child(2) {
    order: 1;
  }
}
.job-top__item span {
  display: block;
  background: #E0D1C1;
  position: absolute;
  width: 40%;
  left: 0;
  height: 20%;
  z-index: 1;
  top: 0;
}
@media screen and (max-width: 1024px) {
  .job-top__item span {
    height: 10%;
  }
}
@media screen and (max-width: 768px) {
  .job-top__item span {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .job-top__item span {
    display: block;
    height: 11%;
    top: 48rem;
    right: 0;
    left: auto;
    width: 60%;
  }
}
.job-top__item img {
  position: relative;
  z-index: 3;
  width: 65%;
}
@media screen and (max-width: 768px) {
  .job-top__item img {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 575px) {
  .job-top__item {
    margin-top: 4rem;
  }
}
.job-top__top {
  margin-bottom: 5.5rem;
}
.job-top__txt {
  height: 23rem;
  overflow-y: auto;
  padding-right: 1.5rem;
}
.job-top__txt::-webkit-scrollbar {
  width: 0.5rem;
  height: 3px;
}
.job-top__txt::-webkit-scrollbar-track {
  background: url("../img/Line.svg");
  background-size: 1px;
  background-repeat: no-repeat;
  background-position: right;
}
.job-top__txt::-webkit-scrollbar-thumb {
  background-color: #3F3939;
  border-radius: 3px;
}

.job-title {
  font-family: "Raleway", sans-serif;
}
.job-title p {
  text-align: right;
}
@media screen and (max-width: 550px) {
  .job-title p {
    text-align: left;
  }
}
.job-title__active {
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 170%;
  color: #3F3939;
}
.job-title__none {
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 170%;
  color: #3F3939;
}
.job-title__text {
  font-family: "Georgia", sans-serif;
  color: #3F3939;
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 125%;
  letter-spacing: 3px;
  text-align: right;
}
.job-title__text::first-letter {
  color: #F1802E;
}
.job-title__text::after {
  content: "";
  width: 41%;
  height: 2px;
  position: absolute;
  background: #3F3939;
  top: 16.375rem;
  right: 0;
  opacity: 0.7;
}
@media screen and (max-width: 1040px) {
  .job-title__text::after {
    width: 59%;
  }
}
@media screen and (max-width: 868px) {
  .job-title__text::after {
    width: 65%;
  }
}
@media screen and (max-width: 550px) {
  .job-title__text::after {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .job-title__text {
    font-size: 2.75rem;
    text-align: left;
  }
}

.job-center {
  margin-top: 5.438rem;
}
.job-center__title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 140%;
  color: #F1802E;
}
.job-center__scrl {
  direction: rtl;
  height: 21rem;
  overflow-y: auto;
  margin-top: 1.5rem;
}
.job-center__scrl::-webkit-scrollbar {
  width: 0.5rem;
  height: 3px;
}
.job-center__scrl::-webkit-scrollbar-track {
  background: url("../img/Line.svg");
  background-size: 1px;
  background-repeat: no-repeat;
  background-position: left;
}
.job-center__scrl::-webkit-scrollbar-thumb {
  background-color: #3F3939;
  border-radius: 3px;
}
.job-center__txt {
  padding-left: 1.5rem;
  direction: ltr;
}
.job-center__img {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .job-center__img {
    margin-top: 2rem;
    justify-content: flex-start;
  }
}
.job-center__img img {
  width: 80%;
}
@media screen and (max-width: 550px) {
  .job-center__img img {
    width: 100%;
  }
}

.job-bottom-1 {
  margin-top: 5.438rem;
}
@media screen and (max-width: 550px) {
  .job-bottom-1 {
    margin-top: 1rem;
  }
}
.job-bottom-1__block:nth-child(1) img {
  width: 30%;
}
@media screen and (max-width: 550px) {
  .job-bottom-1__block:nth-child(1) img {
    width: 49.4%;
  }
}
@media screen and (max-width: 374px) {
  .job-bottom-1__block:nth-child(1) img {
    width: 48.4%;
  }
}
.job-bottom-1__block:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}
.job-bottom-1__block:nth-child(2) .job-bottom-1__item {
  display: flex;
}
.fold-s4 {
  width: 6.25rem;
  height: 5rem;
  margin-right: 1rem;
}

.fold-s42 {
  background-image: url("../img/s4-6.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 27rem;
  padding-top: 6.25rem;
  text-align: right;
  padding-right: 2.563rem;
  padding-bottom: 2.563rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 160%;
  color: #000000;
}

.job-bottom-2 {
  margin-top: 0.625rem;
}
@media screen and (max-width: 550px) {
  .job-bottom-2__block:nth-child(1) {
    order: 2;
    margin-top: 4rem;
  }
  .job-bottom-2__block:nth-child(2) {
    order: 1;
  }
}
.job-bottom-2__img {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .job-bottom-2__img {
    justify-content: flex-start;
  }
}
.job-bottom-2__img span {
  position: absolute;
  width: 40%;
  height: 30%;
  background: #E5D4C3;
  z-index: 1;
  left: 0;
  bottom: 0;
}
@media screen and (max-width: 1024px) {
  .job-bottom-2__img span {
    width: 30%;
    height: 28%;
  }
}
@media screen and (max-width: 768px) {
  .job-bottom-2__img span {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .job-bottom-2__img span {
    display: block;
    height: 14%;
    width: 60%;
  }
}
.job-bottom-2__img img {
  position: relative;
  z-index: 3;
  margin-right: 5rem;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .job-bottom-2__img img {
    margin-right: 0;
  }
}
@media screen and (max-width: 550px) {
  .job-bottom-2__img img {
    margin-left: 20%;
  }
}
.job-bottom-2__scrl {
  margin-top: 7.75rem;
}
.job-bottom-2__text {
  height: 20rem;
  overflow-y: auto;
  padding-right: 1.5rem;
}
.job-bottom-2__text::-webkit-scrollbar {
  width: 0.5rem;
  height: 3px;
}
.job-bottom-2__text::-webkit-scrollbar-track {
  background: url("../img/Line.svg");
  background-size: 1px;
  background-repeat: no-repeat;
  background-position: right;
}
.job-bottom-2__text::-webkit-scrollbar-thumb {
  background-color: #3F3939;
  border-radius: 3px;
}

.bot-block {
  width: 100%;
  height: 6rem;
  background: #E5D4C3;
}

.gen-creat__row {
  position: relative;
  padding-bottom: 1.563rem;
}
.gen-creat__bg {
  position: absolute;
  width: 40%;
  height: 100%;
  left: 0;
  background: #3F3939;
  z-index: 1;
}
@media screen and (max-width: 1024px) {
  .gen-creat__bg {
    width: 60%;
  }
}
@media screen and (max-width: 550px) {
  .gen-creat__bg {
    width: 90%;
  }
}
.gen-creat__bg2 {
  position: absolute;
  width: 70%;
  height: 100%;
  left: 0;
  background: #3F3939;
  z-index: 1;
}
@media screen and (max-width: 1254px) {
  .gen-creat__bg2 {
    width: 80%;
  }
}
@media screen and (max-width: 1102px) {
  .gen-creat__bg2 {
    width: 85%;
  }
}
@media screen and (max-width: 1024px) {
  .gen-creat__bg2 {
    width: 90%;
  }
}
@media screen and (max-width: 550px) {
  .gen-creat__bg2 {
    width: 100%;
  }
}
.gen-creat__top {
  position: relative;
  z-index: 3;
}
.gen-creat__nav img {
  width: 1.125rem;
  height: 1.125rem;
}
.gen-creat__nav span {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.29);
}
.gen-creat__nav a {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.29);
}
.gen-creat__title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 125%;
  color: #D6AB7D;
  margin-top: 2.375rem;
}
@media screen and (max-width: 550px) {
  .gen-creat__title {
    font-size: 2.6rem;
  }
}

.gen-st {
  margin-top: 2.813rem;
}
.gen-st__row {
  position: relative;
}
.gen-st__nav img {
  width: 1.125rem;
  height: 1.125rem;
}
.gen-st__nav span {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 125%;
  color: #3F3E3E;
}
.gen-st__nav a {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 125%;
  color: #3F3E3E;
}
.gen-st__title {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 125%;
  color: #3F3939;
  margin-top: 2.375rem;
}
.gen-st__title::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 1px;
  background: #3D3B39;
  left: 0;
  bottom: -2rem;
}
.gen-st__title span {
  font-size: 1.5rem;
}

.gen-scroll-right {
  height: 62.5rem;
  overflow-y: auto;
  padding-right: 1.5rem;
}
.gen-scroll-right::-webkit-scrollbar {
  width: 0.5rem;
  height: 100%;
}
.gen-scroll-right::-webkit-scrollbar-track {
  background: url("../img/Line.svg");
  background-size: 0.188rem;
  background-repeat: no-repeat;
  background-position: right;
}
.gen-scroll-right::-webkit-scrollbar-thumb {
  width: 15px;
  background-color: #3F3939;
  border-radius: 3px;
}

.creativ {
  margin-top: 6.75rem;
}
.creativ__block {
  height: 24.063rem;
}
.creativ__block:hover .creativ__link {
  opacity: 1;
}
@media screen and (max-width: 768px) {
  .creativ__block {
    margin-top: 2rem;
  }
}
.creativ__link {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 125%;
  text-decoration-line: underline;
  color: #FFFFFF;
  transition: 0.3s;
  opacity: 0;
}
.creativ__link:hover {
  color: #FFFFFF;
  opacity: 0.7;
}
.creativ__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 23px 30px rgba(179, 137, 93, 0.5);
}
.creativ__content span {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.2rem;
  font-size: 2.25rem;
  line-height: 125%;
  color: #FFFFFF;
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 1024px) {
  .creativ__content span {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 768px) {
  .creativ__content span {
    font-size: 2.25rem;
  }
}

.poez {
  margin-top: 10.75rem;
}
@media screen and (max-width: 992px) {
  .poez__block {
    margin-top: 8rem;
  }
}
.poez__content {
  box-shadow: 0px 17px 30px rgba(214, 171, 125, 0.3);
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.438rem;
}
.poez__img {
  margin-top: -4rem;
}
.poez__img img {
  width: 12.813rem;
  height: 18.125rem;
}
.poez__title {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  color: #32201F;
  margin-top: 1.563rem;
  flex: 1 1 auto;
}
@media screen and (max-width: 1024px) {
  .poez__title {
    font-size: 1rem;
  }
}
.poez__next {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 125%;
  text-decoration-line: underline;
  color: #32201F;
  margin-top: 2.438rem;
  display: block;
}
@media screen and (max-width: 1024px) {
  .poez__next {
    font-size: 1rem;
  }
}

.gen-kts {
  margin-top: 6.563rem;
  padding-bottom: 7rem;
}
@media screen and (max-width: 578px) {
  .gen-kts__block {
    margin-bottom: 6rem;
  }
}
.gen-kts__images {
  height: 22.875rem;
  width: 18.5rem;
  border: 1px solid #3D3B39;
}
@media screen and (max-width: 1024px) {
  .gen-kts__images {
    height: 14.875rem;
    width: 11.5rem;
  }
}
@media screen and (max-width: 578px) {
  .gen-kts__images {
    height: 22.875rem;
    width: 18.5rem;
  }
}
.gen-kts__img {
  background: #EBD3B4;
  box-shadow: 0px 17px 30px rgba(210, 142, 95, 0.3);
  height: 100%;
  width: 100%;
  transform: translate(1.875rem, 1.875rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.gen-kts__img2 {
  background: #F1802E;
}
.gen-kts__items {
  display: flex;
  flex-direction: column;
}
.gen-kts__itemg {
  border-bottom: 1px solid #3F3939;
  padding-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
}
@media screen and (max-width: 578px) {
  .gen-kts__itemg {
    padding-left: 1rem;
  }
}
.gen-kts__itemg span {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  text-transform: uppercase;
  color: #32201F;
}
.gen-kts__itemg123 {
  border-bottom: 1px solid #3F3939;
  padding-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
}
@media screen and (max-width: 578px) {
  .gen-kts__itemg123 {
    padding-left: 1rem;
  }
}
.gen-kts__itemg123 span {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  text-transform: uppercase;
  color: #32201F;
}
.gen-kts__start {
  display: flex;
  align-items: center;
}
.gen-kts__start img {
  margin-right: 0.375rem;
}
.gen-kts__arrow {
  margin-right: 1rem;
}
.gen-kts__arrow img {
  width: 2.375rem;
  height: auto;
}

.gen-of-text {
  margin-top: 6.063rem;
}
.gen-of-text__glav {
  border-left: 1px solid #D28E5F;
  padding-left: 0.9rem;
}
.gen-of-text__glav div {
  margin-top: 1rem;
}
.gen-of-text__glav a {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  color: #B3895D;
  transition: 0.3s;
}
.gen-of-text__glav a:hover {
  text-decoration: underline;
  opacity: 0.8;
}
.gen-of-text__op {
  margin-top: 7rem;
}

.stihs {
  display: flex;
  justify-content: center;
}
.stihs p {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 100%;
  color: #000000;
}

.vidp {
  max-width: 52rem;
  height: 28.75rem;
  margin: 1rem 0;
}
.vidp video {
  width: 100%;
  height: 100%;
}

.mySwiper6 {
  max-width: 43.875rem;
  max-height: 29.25rem;
  margin-left: 0;
}

.swiper-button-next {
  background-image: url("../img/Arrow11.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.875rem;
  left: 5rem;
}

.swiper-button-prev {
  background-image: url("../img/Arrow12.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.875rem;
}

.swiper-button-next, .swiper-button-prev {
  margin-top: 0px;
}

.swiper-button-next::after, .swiper-button-prev::after {
  content: "";
}

.nav-btns {
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.film-title {
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 125%;
  color: #3F3939;
  margin-bottom: 3rem;
}

.performances__title {
  font-family: "Raleway", sans-serif;
  margin: 6.25rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 125%;
  color: #866859;
}
.performances__block {
  margin-bottom: 3.813rem;
}
.performances__cont iframe {
  width: 100%;
  height: 23.5rem;
}
.performances__descr {
  background: white;
  padding: 1.563rem 1.25rem;
}
.performances__descr span {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 125%;
  color: #3F3939;
}

.poem {
  margin-bottom: 5rem;
}
.poem__row {
  position: relative;
}
.poem__title {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 125%;
  color: #3F3939;
  margin-top: 2.375rem;
  position: relative;
}
.poem__title::after {
  content: "";
  position: absolute;
  opacity: 0.7;
  width: 80%;
  border-bottom: 1px solid #3D3B39;
  left: 0;
  bottom: -1rem;
}
.poem__title span {
  font-size: 1.5rem;
}

#button-up {
  display: none;
  right: 1rem;
  margin: 0;
  position: fixed;
  bottom: 6.25rem;
  outline: none;
  width: 3.25rem;
  color: white;
  z-index: 1000;
  text-decoration: none;
  padding: 1rem;
  background: #3F3939;
  text-align: center;
  box-shadow: 0 5px 20px white;
  cursor: pointer;
}

.tabheader__item_active .gen-kts__arrow img {
  transform: rotate(90deg);
}

.politic {
  margin-top: 11.125rem;
}
.politic__block:first-child .politic__cont {
  background: linear-gradient(112.97deg, #F1802E 39.51%, #EBD3B4 97.82%);
  box-shadow: 0px 17px 30px rgba(241, 128, 46, 0.5);
}
.politic__block:last-child .politic__cont {
  background: linear-gradient(109.85deg, #AE9D91 43.82%, #FFF8EE 102.22%);
  box-shadow: 0px 17px 30px rgba(214, 171, 125, 0.5);
}
@media screen and (max-width: 768px) {
  .politic__block:last-child .politic__cont {
    margin-top: 2rem;
  }
}
.politic__block3:nth-child(1) .politic__cont3 {
  background: linear-gradient(118.31deg, #F1802E 23.39%, #EBD3B4 111.09%);
}
.politic__block3:nth-child(2) .politic__cont3 {
  background: linear-gradient(114.9deg, #AE9D91 19.27%, #FFF8EE 111.29%);
}
.politic__block3:nth-child(3) .politic__cont3 {
  background: linear-gradient(118.31deg, #F1802E 23.39%, #EBD3B4 111.09%);
}
.politic__block3:nth-child(4) .politic__cont3 {
  background: linear-gradient(114.9deg, #AE9D91 19.27%, #FFF8EE 111.29%);
}
.politic__cont {
  padding: 1rem 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .politic__cont {
    flex-direction: column;
    align-items: flex-start;
  }
}
.politic__cont img {
  width: 14.625rem;
  height: 20.125rem;
  margin-left: 3.5rem;
}
.politic__cont3 {
  box-shadow: 0px 17px 30px rgba(241, 128, 46, 0.5);
  height: 17.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.politic__cont3 a {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 125%;
  text-decoration: underline;
  color: #32201F;
  margin-top: 1.2rem;
}
.politic__right {
  margin-left: 3.813rem;
}
@media screen and (max-width: 1024px) {
  .politic__right {
    margin-top: 2rem;
  }
}
.politic__right a {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 125%;
  text-decoration-line: underline;
  color: #32201F;
}
.politic__name {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 125%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.politic__name span {
  font-size: 1rem;
  font-weight: 500;
}
.politic__name3 {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 125%;
  /* or 30px */
  text-align: center;
  color: #FFFFFF;
}

.politic1 {
  margin-top: 11.125rem;
}
.politic1__cont {
  background: #FFFFFF;
  padding: 1rem 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .politic1__cont {
    flex-direction: column;
    align-items: flex-start;
  }
}
.politic1__cont img {
  width: 14.625rem;
  height: 20.125rem;
  margin-left: 3.5rem;
}
.politic1__right {
  margin-left: 3.813rem;
}
@media screen and (max-width: 1024px) {
  .politic1__right {
    margin-top: 2rem;
  }
}
.politic1__right a {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 125%;
  text-decoration-line: underline;
  color: #32201F;
}
.politic1__name {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 125%;
  color: black;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.politic1__name span {
  font-size: 1rem;
  font-weight: 500;
}

.poez-tit {
  font-family: "Georgia", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 125%;
  color: #3F3939;
  margin-top: 5rem;
}

.footer {
  color: white;
  padding-bottom: 2rem;
}
.footer__row {
  position: relative;
}
.footer__logo {
  position: absolute;
  left: 0;
}
.footer__logo span {
  position: absolute;
  bottom: 1rem;
  right: -7rem;
  font-family: "Menorah", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 170%;
}
@media screen and (max-width: 1024px) {
  .footer__logo span {
    left: 7rem;
    right: auto;
  }
}
@media screen and (max-width: 1024px) {
  .footer__logo {
    position: relative;
  }
}
@media screen and (max-width: 422px) {
  .footer__img img {
    width: 50%;
  }
}
.footer__bot {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 170%;
  opacity: 0.5;
  margin-top: 1rem;
}
@media screen and (max-width: 1024px) {
  .footer__bot {
    justify-content: flex-end;
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 4.813rem;
}
.footer-nav__block {
  margin-left: 8.25rem;
}
.footer-nav__block:first-child {
  margin-left: 0;
}
@media screen and (max-width: 1224px) {
  .footer-nav__block {
    margin-left: 5.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .footer-nav__block {
    flex: 0 0 auto;
    width: 50%;
    margin-left: 0;
    margin-top: 2rem;
  }
}
.footer-nav__title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 170%;
  margin-bottom: 1.875rem;
  display: block;
  color: white;
}
.footer-nav__title:hover {
  color: white;
}
.footer-nav__links {
  display: flex;
  flex-direction: column;
}
.footer-nav__links a {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 170%;
  color: #FFFFFF;
  opacity: 0.6;
  margin-bottom: 1.125rem;
}

.footer-nav2 {
  margin-left: 8.25rem;
}
@media screen and (max-width: 1024px) {
  .footer-nav2 {
    margin: 2rem 0;
    width: 100%;
  }
}
.footer-nav2__block {
  margin-bottom: 1.875rem;
}
.footer-nav2__title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 170%;
  margin-bottom: 1.875rem;
  color: white;
}
.footer-nav2__title:hover {
  color: white;
}
.footer-nav2__links {
  display: flex;
  flex-direction: column;
}
.footer-nav2__links a {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 170%;
  color: #FFFFFF;
  opacity: 0.6;
  margin-bottom: 1.125rem;
}

body {
  font-family: "Open Sans", sans-serif;
  background: #3F3939;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  padding-bottom: 7rem;
}

.main-dop {
  background: #F6F4F3;
  margin-top: 10rem;
}