.job {
background-color: #ebe9e6f8;

    &__row {
        background:url('../img/s4-1bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-top: 6.75rem;
        padding-bottom: 11.438rem;
    }

    &__item {
    }
}
.container {
}
.row {
}
.job-top {
    &__block {
        &:nth-child(1) {
            @media screen and (max-width:768px) {
                order: 2;
            }
        }
        &:nth-child(2) {
            @media screen and (max-width:768px) {
                order: 1;
            }
        }
    }

    &__item {
        span {
            display: block;
            background: #E0D1C1;
            position: absolute;
            width: 40%;
            left: 0;
            height: 20%;
            z-index: 1;
            top: 0;
            @media screen and (max-width:1024px) {
                height: 10%;
            }
            @media screen and (max-width:768px) {
             display: none;
            }
            @media screen and (max-width:550px) {
                display: block;
                height: 11%;
                top: 48rem;
                right: 0;
                left: auto;
                width: 60%;
            }
        }
        img {
            position: relative;
            z-index: 3;
            width:65%;
            @media screen and (max-width:768px) {
                margin-top: 2rem;
            }
        }
        @media screen and (max-width:575px) {
         margin-top: 4rem;
      }
    }

    &__top {
        margin-bottom: 5.5rem;
    }

    &__scrl {
    }

    &__txt {
        height: 23rem;
        overflow-y: auto;
        padding-right: 1.5rem;
        &::-webkit-scrollbar { width: 0.5rem; height: 3px;}
        &::-webkit-scrollbar-track { background:url('../img/Line.svg'); background-size:1px; background-repeat:no-repeat; background-position: right;}
        &::-webkit-scrollbar-thumb {
            // width: 15px;
            background-color: #3F3939;
            border-radius: 3px;
        }
    }
}
.col-md-6 {
}
.job-title {
    font-family: 'Raleway', sans-serif;
    p {
        text-align: right;
        @media screen and (max-width:550px) {
          text-align: left;
      }
    }
    &__active {
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 170%;
        color: #3F3939;
    }

    &__none {
        font-style: normal;
        font-weight: 400;
        font-size: 1.563rem;
        line-height: 170%;
        color: #3F3939;
    }

    &__text {
        font-family: 'Georgia', sans-serif;
        color: #3F3939;
        font-style: normal;
        font-weight: 600;
        font-size: 3.75rem;
        line-height: 125%;
        letter-spacing: 3px;
        text-align: right;
        &::first-letter {
            color: #F1802E;
        }
        &::after {
            content: "";
            width:41%;
            height: 2px;
            position: absolute;
            background:#3F3939;
            top: 16.375rem;
            right: 0;
            opacity: 0.7;
            @media screen and (max-width:1040px) {
                // top: 9.375rem;
                width:59%;
            }
            @media screen and (max-width:868px) {
                width:65%;
            }
            @media screen and (max-width:550px) {
                width:100%;
            }
        }
        @media screen and (max-width:550px) {
            font-size: 2.75rem;
            text-align: left;
        }
    }
}

.job-center {
margin-top: 5.438rem;
		&__block {
		}

		&__item {
		}

		&__title {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 140%;
            color: #F1802E;
		}

		&__scrl {
            direction: rtl;
            height: 21rem;
            overflow-y: auto;
            margin-top: 1.5rem;
            &::-webkit-scrollbar {
                width: 0.5rem;
                height: 3px;
            }
            &::-webkit-scrollbar-track { background:url('../img/Line.svg'); background-size:1px; background-repeat:no-repeat; background-position: left;}
            &::-webkit-scrollbar-thumb {
                // width: 15px;
                background-color: #3F3939;
                border-radius: 3px;
            }
		}

		&__txt {

            padding-left: 1.5rem;
            direction: ltr;

		}

		&__img {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width:768px) {
                margin-top: 2rem;
                justify-content: flex-start;
            }
            img {
                width:80%;
                @media screen and (max-width:550px) {
                  width: 100%;
                 }
            }
		}
}


.job-bottom-1 {
margin-top: 5.438rem;
@media screen and (max-width:550px) {
  margin-top: 1rem;
 }
		&__block {
            &:nth-child(1) {
                img {
                    width:30%;
                    @media screen and (max-width:550px) {
                      width: 49.4%;
                     }
                     @media screen and (max-width:374px) {
                      width: 48.4%;
                     }
                }
            }
            &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
                margin-top: 3rem;
                .job-bottom-1__item {
                    display: flex;
                }
            }
		}

		&__item {
		}
}

.fold-s4 {
    width:6.25rem;
    height: 5rem;
    margin-right: 1rem;
}
.fold-s42 {
    background-image: url('../img/s4-6.png');
    background-repeat:no-repeat;
    background-size:cover;
    width:27rem;
    padding-top: 6.25rem;
    text-align: right;
    padding-right: 2.563rem;
    padding-bottom: 2.563rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 160%;
    color: #000000;
}


.job-bottom-2 {
margin-top: 0.625rem;
		&__block {
      @media screen and (max-width:550px) {
        &:nth-child(1) {
          order: 2;
          margin-top: 4rem;
        }
        &:nth-child(2) {
          order: 1;
        }
      }
		}

		&__img {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width:768px) {
                justify-content: flex-start;
            }
            span {
                position: absolute;
                width:40%;
                height: 30%;
                background: #E5D4C3;
                z-index: 1;
                left: 0;
                bottom: 0;
                @media screen and (max-width:1024px) {
                    width:30%;
                    height: 28%;
                }
                @media screen and (max-width:768px) {
                    display: none;
                }
                @media screen and (max-width:550px) {
                  display: block;
                  height: 14%;
                  width:60%;
              }
            }
            img {
                position: relative;
                z-index: 3;
                margin-right: 5rem;
                width:50%;
                @media screen and (max-width:768px) {
                    margin-right: 0;
                }
                @media screen and (max-width:550px) {
                  margin-left: 20%;
              }
            }
		}

		&__item {
		}

		&__scrl {
            margin-top: 7.75rem;
		}

		&__text {
            height: 20rem;
            overflow-y: auto;
            padding-right: 1.5rem;
            &::-webkit-scrollbar { width: 0.5rem; height: 3px;}
            &::-webkit-scrollbar-track { background:url('../img/Line.svg'); background-size:1px; background-repeat:no-repeat; background-position: right;}
            &::-webkit-scrollbar-thumb {
                // width: 15px;
                background-color: #3F3939;
                border-radius: 3px;
            }
		}
}
.bot-block {
    width:100%;
    height: 6rem;
    background: #E5D4C3;
}
