.study {
    background: #DFD0C0;
    font-family: 'Raleway', sans-serif;
    &__row {
        position: relative;
        background:url('../img/s3-5.png');
        background-repeat:no-repeat;
        width:100%;
        background-position:bottom;
        background-size:100% 40rem;
        @media screen and (max-width:550px) {
          background-size:240% 40rem;
        }
    }

    &__item {
    }
}

.study-top {
display: flex;
// align-items: center;

    &__block {
        &:last-child {
            background:url('../img/s3-bg1.svg');
            background-size:cover;
            background-repeat: no-repeat;
            background-position: right -2rem;
            color: #FFFFFF;
            padding: 0;
            @media screen and (max-width:768px) {
                margin-top: 2rem;
            }
        }
    }

    &__item {
    }

    &__text {
        max-width:32.25rem
    }

    &__top {
        padding: 0 1rem;
        margin-bottom: 1.313rem;
        img {
            margin-left: 0.4rem;
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            opacity: 0.7;
            margin-left: 3rem;
            max-width:24.688rem;
            @media screen and (max-width:768px) {
                max-width:none;
            }
        }
        span {
            display: flex;
            justify-content: flex-end;
            margin-right: 4rem;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 125%;
            opacity: 0.7;
            @media screen and (max-width:1024px) {
                font-size: 0.7rem;
                margin-right: 0;
            }
        }
    }
}
.fol-d {
    margin-left: 4rem;
}
.study-top-title {
    font-family: 'Open Sans', sans-serif;
    padding-top: 3rem;
    &__active {
        font-style: normal;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 170%;
        color: #3F3939;
    }

    &__none {
        font-style: normal;
        font-weight: 400;
        font-size: 1.563rem;
        line-height: 170%;
        color: #3F3939;
    }

    &__txt {
        font-family: 'Georgia', sans-serif;
        color: #3F3939;
        font-style: normal;
        font-weight: 600;
        font-size: 3.75rem;
        line-height: 125%;
        letter-spacing: 3px;
        margin-bottom: 5.5rem;
        &::first-letter {
            color: #F1802E;
        }
        &::after {
          content: "";
          width:41%;
          height: 2px;
          position: absolute;
          background:black;
          top: 13.375rem;
          left: 0;
          opacity: 0.7;
          @media screen and (max-width:1040px) {
              // top: 9.375rem;
              width:59%;
          }
          @media screen and (max-width:868px) {
              width:65%;
          }
          @media screen and (max-width:550px) {
              width:100%;
          }
      }
    }
}
.fold-3 {
    width:11.188rem;
    height: 10.188rem;
    @media screen and (max-width:1024px) {
        margin: 2rem 0;
    }
    @media screen and (max-width:575px) {
      order: 3;
      display: inline-block;
      position: absolute;
      // top: 10rem;
      bottom:-1.9rem;
      right: 0;
      height: 23%;
   }
}
.fold-2 {
    width:100%;
    @media screen and (max-width:1024px) {
        width:50%;
    }
    @media screen and (max-width:575px) {
      order: 2;
      display: inline-block;
   }
}
.study-center {
margin-top: 5.438rem;
    &__item {
        display: flex;
        align-items:flex-end;
        justify-content: space-between;
        position: relative;
        z-index: 3;
        @media screen and (max-width:1024px) {
            flex-direction: column;
            align-items:flex-start;
        }
    }

    &__text {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 160%;
        color: #000000;
        max-width:33rem;
        margin: 0 2rem;
        margin-top: 3rem;
        @media screen and (max-width:1024px) {
           margin: 2rem 0 0 0;
           max-width:none;
        }
        @media screen and (max-width:575px) {
          order: 1;
       }
    }
}
.study-bottom {
margin-top: 6.375rem;
padding-bottom: 5.688rem;
    &__item {
        display: flex;
        align-items:flex-end;
        @media screen and (max-width:814px) {
            flex-direction: column;
            align-items:flex-start;
        }
    }

    &__left {
        margin-right: 1.938rem;
        p {
            max-width: 32.188rem;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 160%;
            color: #000000;
            @media screen and (max-width:814px) {
               max-width:none;
            }
        }
    }

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 2.25rem;
        line-height: 160%;
        color: #F1802E;
    }

    &__right {
    }
}
