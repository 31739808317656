.performances {

    &__row {
    }

    &__title {
        font-family: 'Raleway', sans-serif;
        margin: 6.25rem 0;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 125%;
        color: #866859;

    }

    &__item {
    }

    &__block {
        margin-bottom: 3.813rem;
    }

    &__cont {
        iframe {
            width: 100%;
            height: 23.5rem;
        }
    }

    &__descr {
        background:white;
        padding: 1.563rem 1.25rem;
        span {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 125%;
            color: #3F3939;
        }
    }
}
.container {
}
.row {
}
.col-md-6 {
}
