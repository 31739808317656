// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/sec-1";
@import "./components/kids";
@import "./components/study";
@import "./components/job";
@import "./components/gen-creact";
@import "./components/creativ";
@import "./components/poez";
@import "./components/gen-kts";
@import "./components/oftext";
@import "./components/perf";
@import "./components/poem";
@import "./components/politic";
@import "./components/footer";

// тестовые стили, удалите их


body {
  font-family: 'Open Sans', sans-serif;
  background: #3F3939;
  // backdrop-filter: blur(132px);
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 1 auto;
  padding-bottom: 7rem;
}

.main-dop {
  background: #F6F4F3;
  margin-top: 10rem;
}
