.politic {
    margin-top: 11.125rem;
    &__row {
    }

    &__item {
    }

    &__blocks {
    }

    &__block {

        &:first-child {
            .politic__cont {
                background: linear-gradient(112.97deg, #F1802E 39.51%, #EBD3B4 97.82%);
box-shadow: 0px 17px 30px rgba(241, 128, 46, 0.5);
            }
        }
        &:last-child {
            .politic__cont {
            @media screen and (max-width:768px) {
                margin-top: 2rem;
            }
            background: linear-gradient(109.85deg, #AE9D91 43.82%, #FFF8EE 102.22%);
box-shadow: 0px 17px 30px rgba(214, 171, 125, 0.5);
        }
        }
    }
    &__block3 {

      &:nth-child(1) {
        .politic__cont3 {
          background: linear-gradient(118.31deg, #F1802E 23.39%, #EBD3B4 111.09%);
        }

      }
      &:nth-child(2) {
        .politic__cont3 {
          background: linear-gradient(114.9deg, #AE9D91 19.27%, #FFF8EE 111.29%);
        }
      }
      &:nth-child(3) {
        .politic__cont3 {
          background: linear-gradient(118.31deg, #F1802E 23.39%, #EBD3B4 111.09%);
        }
      }
      &:nth-child(4) {
        .politic__cont3 {
          background: linear-gradient(114.9deg, #AE9D91 19.27%, #FFF8EE 111.29%);
        }
      }
    }

    &__cont {
        padding: 1rem 0;
        display: flex;
        align-items:center;
        @media screen and (max-width:1024px) {
            flex-direction: column;
            align-items:flex-start;
        }
        img {
            width: 14.625rem;
            height: 20.125rem;
            margin-left: 3.5rem;
        }
    }

    &__cont3 {
      box-shadow: 0px 17px 30px rgba(241, 128, 46, 0.5);
      height: 17.5rem;
      display: flex;
      flex-direction: column;
      align-items:center;
      justify-content: center;
      a {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 125%;
        text-decoration: underline;
        color: #32201F;
        margin-top: 1.2rem;
      }

    }

    &__right {
        margin-left: 3.813rem;
        @media screen and (max-width:1024px) {
            margin-top: 2rem;
        }
        a {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 125%;
            text-decoration-line: underline;
            color: #32201F;

        }
    }

    &__name {
        font-family: 'Georgia', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 125%;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        span {
            font-size: 1rem;
            font-weight: 500;
        }

    }
    &__name3 {
      font-family: 'Georgia', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 125%;
      /* or 30px */

      text-align: center;

      color: #FFFFFF;


  }
}

.politic1 {
  margin-top: 11.125rem;
  &__row {
  }

  &__item {
  }

  &__blocks {
  }

  &__block {
  }

  &__cont {
    background: #FFFFFF;
      padding: 1rem 0;
      display: flex;
      align-items:center;
      @media screen and (max-width:1024px) {
          flex-direction: column;
          align-items:flex-start;
      }
      img {
          width: 14.625rem;
          height: 20.125rem;
          margin-left: 3.5rem;
      }
  }


  &__right {
      margin-left: 3.813rem;
      @media screen and (max-width:1024px) {
          margin-top: 2rem;
      }
      a {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 125%;
          text-decoration-line: underline;
          color: #32201F;

      }
  }

  &__name {
      font-family: 'Georgia', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 125%;
      color: black;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      span {
          font-size: 1rem;
          font-weight: 500;
      }

  }
}
.poez-tit {
  font-family: 'Georgia', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 125%;
  color: #3F3939;
  margin-top: 5rem;
}
