// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Open Sans", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: 1300px;

  // colors
  --light-color: #fff;
}
