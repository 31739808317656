$menu-bg: #120b07f8;
$menu-color: #ffffff;
$menu-font-size: 1.5rem;
$menu-zindex: 1100;
$hamburger-color: #fff;
$hamburger-width: 50px;
$hamburger-height: 50px;
$hamburger-zindex: $menu-zindex + 10;


.header {
position: fixed!important;
z-index: 1002;
width:100%;
left: 0;
top: 0;
    &__row {
    }

    &__item {
    }
}
.header-desk {
display: flex;
align-items: center;
justify-content: space-between;
padding: 1.563rem 0;
    &__logo {
      span {
        font-family: 'Menorah', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 170%;
        color: #FFFFFF;
      }

    }

    &__nav {
      display: none;
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 170%;
        color: #FFFFFF;
        margin-left: 1.563rem;
        transition: 0.3s;
        &:hover {
          opacity: 0.6;
        }
        &:first-child {
          margin-left: 0;
        }

      }
    }
}




.nav {
  display: block;

}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0);
      opacity:0;
  }
  50% {
    opacity: 0.5;
  }
  to {
      transform: translate3d(0,0,0);
      opacity: 1;
  }
}
#menu {
  background: $menu-bg;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed!important;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: $menu-zindex;

  &.open {
    opacity: 1;
    visibility: visible;

    @for $i from 1 through 10 {
      li:nth-child(#{$i}) {
        animation-delay: $i*0.1s;
      }
    }

    li {
      animation-duration: 1.0s;
      animation-fill-mode: both;
      animation-name: fadeInUp;
    }
  }

  ul {
    &:hover {
      a {
        opacity: 0.5;
      }
    }

    a {
      -webkit-transform: translateZ(0);
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  .main-nav {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      color: $menu-color;
      display: block;
      font-size: $menu-font-size;
      font-weight: bold;
      padding: 1rem 1rem;
      text-decoration: none;
      text-transform: uppercase;
      &:first {
        padding-top: 0;
      }
    }
  }

  .menu-footer {
    padding: 2rem;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;

    ul {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        margin: 0 1rem;
      }
    }

    a {
      color: $menu-color;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}


// Hamburger menu toggler stuff below
.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: $hamburger-height;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: $hamburger-width;
  z-index: $hamburger-zindex;

  span {
    background-color: $hamburger-color;
    content: "";
    display: block;
    height: 2px;
    left: calc(50% - 13px );
    position: absolute;
    top: calc(50% - 1px );
    transform-origin: 50% 50%;
    transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,  transform 0.2s linear;
    width: 26px;

    &:before, &:after {
      background-color: $hamburger-color;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      transform-origin: 50% 50%;
      transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,  transform 0.2s linear;
      width: 26px;
    }

    &:before {
      top: 7px;
    }

    &:after {
      top: -7px;
    }
  }

  &.active {
    span {
      background-color: transparent;
      transition: background 0.2s ease-out;

      &:before, &:after {
        transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
      }

      &:before {
        top: 0;
        transform: rotate3d(0, 0, 1, -45deg);
      }

      &:after {
        top: 0;
        transform: rotate3d(0, 0, 1, 45deg);
      }
    }
  }
}

.head-bg {
  background: #3F3939;
  transition: 0.3s;
}
