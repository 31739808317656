.gen-of-text {
margin-top: 6.063rem;

    &__item {

    }
    &__glav {
      border-left: 1px solid #D28E5F;
      padding-left: 0.9rem;
      div {
        margin-top: 1rem;
      }
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 125%;
        color: #B3895D;
        transition: 0.3s;
        &:hover {
          text-decoration: underline;
          opacity: 0.8;
        }
      }
    }
    &__op {
      margin-top: 7rem;
    }
}
.container {
}
.stihs {
    display: flex;
    justify-content: center;
    p {
        font-family: 'Georgia', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 100%;
        color: #000000;
    }
}
.vidp {
  max-width: 52rem;
  height: 28.75rem;
  margin: 1rem 0;
  video {
      width: 100%;
      height: 100%;
  }
}
.mySwiper6 {
  max-width: 43.875rem;
  max-height: 29.25rem;
  margin-left: 0;
}
.swiper-button-next {
  background-image:url('../img/Arrow11.svg');
  background-repeat:no-repeat;
  background-size:contain;
  width:2.875rem;
  left: 5rem;
}
.swiper-button-prev {
  background-image:url('../img/Arrow12.svg');
  background-repeat:no-repeat;
  background-size:contain;
  width:2.875rem;
}

.swiper-button-next, .swiper-button-prev{
  // position: static;
  // top: 0;
  margin-top: 0px;
  // margin-left: 1.875rem;
}
.swiper-button-next::after, .swiper-button-prev::after {
  content: "";

}
.nav-btns {
  position: relative;
  margin-top: 2rem;
  display: flex;
  align-items:center;
}
.film-title {
  font-style: normal;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 125%;
  color: #3F3939;
  margin-bottom: 3rem;
}
